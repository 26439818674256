import React from "react";
import { Box } from "@chakra-ui/react";

const ChatWidget = () => {
  const chatlioWidgetCode = `<chatlio-widget widgetid="${process.env.GATSBY_CHATLIO_ID}"></chatlio-widget>`;

  return (
    <Box>
      <Box dangerouslySetInnerHTML={{ __html: chatlioWidgetCode }} />
    </Box>
  );
};

export default ChatWidget;

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Helmet } from "react-helmet";
import ChatWidget from "@modules/Layout/components/ChatWidget";
import LeadLanderWidget from "@modules/Layout/components/LeadLanderWidget";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // suspense: true,
    },
  },
});

interface Props {
  children: React.ReactNode;
}

const RootElementWrapper: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
        <ChatWidget />
        <LeadLanderWidget />
      </QueryClientProvider>
    </>
  );
};

export default RootElementWrapper;

import React from "react";

const LeadLanderWidget = () => (
  <>
    <script
      dangerouslySetInnerHTML={{
        __html: `
          var llcookieless = false;
          var formalyze = [];
          formalyze.auto = true;
          formalyze.callback = function(options) {};
          (function() {
            var a = document.createElement("script");
            a.src = "https://lltrck.com/scripts/lt-v3.js?llid=18222";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(a, s);
          })();
        `,
      }}
    />
  </>
);

export default LeadLanderWidget;

import "@fontsource/barlow/100.css";
import "@fontsource/barlow/200.css";
import "@fontsource/barlow/300.css";
import "@fontsource/barlow/400.css";
import "@fontsource/barlow/500.css";
import "@fontsource/barlow/600.css";
import "@fontsource/barlow/700.css";
import "@fontsource/barlow/800.css";
import "@fontsource/barlow/900.css";
import "@fontsource/barlow";
import "@fontsource/hind/300.css";
import "@fontsource/hind/400.css";
import "@fontsource/hind/500.css";
import "@fontsource/hind/600.css";
import "@fontsource/hind/700.css";
import "@fontsource/hind";

import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const theme = {
  textStyles: {
    h1: {
      fontSize: "3.125rem",
      fontFamily: `'Barlow', sans-serif`,
      fontWeight: "700",
      lineHeight: "3.75rem",
      textColor: "blue700",
    },
    h2: {
      fontSize: "3rem",
      fontFamily: `'Barlow', sans-serif`,
      fontWeight: "700",
    },
    h3: {
      fontSize: "1.875rem",
      fontFamily: `'Barlow', sans-serif`,
      fontWeight: "600",
    },
    h4: {
      fontSize: "1.25rem",
      fontFamily: `'Barlow', sans-serif`,
      fontWeight: "600",
    },
    h5: {
      fontSize: "1.125rem",
      fontFamily: `'Barlow', sans-serif`,
      fontWeight: "600",
    },
    h6: {
      fontSize: "1rem",
      fontFamily: `'Barlow', sans-serif`,
      fontWeight: "600",
    },
    hind_xs: {
      fontSize: "0.75rem",
      fontFamily: `'Hind', sans-serif`,
      fontWeight: "400",
    },
    hind_s: {
      fontSize: "0.875rem",
      fontFamily: `'Hind', sans-serif`,
      fontWeight: "400",
    },
    hind_m: {
      fontSize: "1rem",
      fontFamily: `'Hind', sans-serif`,
      fontWeight: "400",
    },
    hind_r: {
      fontSize: "1.125rem",
      fontFamily: `'Hind', sans-serif`,
      fontWeight: "400",
      lineHeight: "1.6875rem",
    },
    hind_e: {
      fontSize: "1.375rem",
      fontFamily: `'Hind', sans-serif`,
      fontWeight: "400",
      lineHeight: "1.6875rem",
    },
    hind_l: {
      fontSize: "1.5rem",
      fontFamily: `'Hind', sans-serif`,
      fontWeight: "400",
    },
    hind_xl: {
      fontSize: "2rem",
      fontFamily: `'Hind', sans-serif`,
      fontWeight: "400",
    },
  },
  fonts: {
    heading: `'Barlow', sans-serif`,
    body: `'Hind', sans-serif`,
  },

  styles: {
    global: {
      body: { color: "misc800", fontSize: "1.125rem" },
      ":focus, :focus-visible, *[data-focus]": {
        boxShadow: "none !important",
        outline: "none !important",
        borderColor: "inherit !important",
      },
    },
  },
  layerStyles: {
    base: {
      bg: "gray.50",
      border: "2px solid",
      borderColor: "gray.500",
    },
    selected: {
      bg: "teal.500",
      color: "teal.700",
      borderColor: "orange.500",
    },
  },

  colors: {
    primary: "#3672AF",
    primary700: "#1A3F63",
    misc600: "#656565",
    misc650: "#595959",
    misc700: "#555555",
    misc750: "#434343",
    misc800: "#1B1B1B",
    blue500: "#3570AC",
    blue700: "#204367",
    blue900: "#021228",
    secondary: "#F6C255",
    black: "#000000",
    grey100: "#F6F5F4",
    grey200: "#BCBCBC",
    grey300: "#A4A4A4",
    grey50: "#F6F4EE",
    white50: "#F8F8F8",
    white20: "#F5F5F5",
    white10: "#F2F2F2",
    white: "#FFFFFF",
  },
  fontSizes: {
    h1: "3.125rem",
    h2: "3rem",
    h3: "1.875rem",
    h4: "1.25rem",
    h5: "1.125rem",
    xs: "0.75rem",
    s: "0.875rem",
    m: "1rem",
    r: "1.125rem",
    l: "1.5rem",
    xl: "2rem",
    h1m: "2.25rem",
    h2m: "2rem",
    h3m: "1.75rem",
    h1s: "2rem",
    h2s: "1.875rem",
    h3s: "1.5rem",
  },
  fontWeights: {
    regular: 400,
  },
  breakpoints: {
    customLg: "1200px",
    xl: "1440px",
  },
  defaultProps: {
    colorScheme: "green",
  },
  components: {
    IconButton: {
      baseStyle: {
        borderColor: "transparent",
        borderWidth: "0",
      },
      focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
    Card: {
      varaints: {
        hasBorder: {
          border: "1px solid",
          borderColor: "red",
        },
      },
    },
    Button: {
      focus: {
        boxShadow: "none",
        outline: "none",
      },
      baseStyle: {
        fontFamily: `'Barlow', sans-serif`,
        _focus: {
          boxShadow: "none !important",
        },
        borderWidth: 0,
        borderColor: "transparent",
        lineHeight: 1,
        height: "auto",
        paddingTop: "1.1875rem",
        paddingBottom: "1.25rem",
      },
      variants: {
        primary: {
          bg: "#3672AF",
          borderColor: "#3672AF",
          borderRadius: "4px",
          fontSize: "1.125rem",
          color: "#ffffff",
          "&:hover": {
            bg: "#285482",
            textDecoration: "none",
          },
        },
        secondary: {
          bg: "#ffffff",
          borderRadius: "4px",
          fontSize: "1.125rem",
          color: "#3672AF",
          "&:hover": {
            textDecoration: "none",
          },
        },
        secondPrimary: {
          bg: "#ffffff",
          borderRadius: "4px",
          fontSize: "1.125rem",
          color: "#3672AF",
          "&:hover": {
            textDecoration: "none",
          },
        },
        outline: {
          bg: "transparent",
          borderRadius: "4px",
          color: "#3672AF",
          border: "2px solid #3672AF",
          fontSize: "1.125rem",
          "&:hover": {
            bg: "#F5F5F5",
            textDecoration: "none",
          },
        },
        primaryOutline: {
          bg: "transparent",
          borderRadius: "4px",
          fontSize: "1.125rem",
          color: "#3672AF",
          border: "2px solid #3672AF",
          "&:hover": {
            textDecoration: "none",
          },
        },
        ghost: {
          bg: "transparent",
          fontSize: "1.125rem",
          border: "2px solid transparent",
          color: "#3672AF",
          paddingLeft: "0",
          "&:hover": {
            textDecoration: "none",
          },
        },
      },
    },
    Accordion: {},
  },
};

export default extendTheme(
  theme,
  withDefaultColorScheme({
    colorScheme: "primary",
  })
);
